import React from 'react'
import './style.scss'
import { Link } from 'react-router-dom'
import { MenuOutlined } from '@ant-design/icons'

export default function NavBar() {
	return (
		<nav>
			<div className="wrap">
				<div className="flex jcsb ci">
					<aside>
						<Link to="/">
							<img src="/logo.png" alt="" />
						</Link>
					</aside>
					<MobSideBar />
					<aside className="hide-on-mob">
						<ul>
							<li>
								<Link to="/">Home</Link>
							</li>
							<li>
								<Link to="/rules">Rules</Link>
							</li>
							<li>
								<Link to="/form_status">Check Form Status</Link>
							</li>
							<li>
								<Link to="/notices">Notices</Link>
							</li>
							<li>
								<Link to="/about">About Us</Link>
							</li>
						</ul>
					</aside>
				</div>
			</div>
		</nav>
	)
}

const navItems = [
	{ text: 'Home', goto: '/' },
	{ text: 'Rules', goto: '/rules' },
	{ text: 'Check Form Status', goto: '/form_status' },
	{ text: 'Notices', goto: '/notices' },
	{ text: 'About Us', goto: '/about' },
]

const MobSideBar = () => {
	const [expand, setExpand] = React.useState(false)

	return (
		<div className="sidebar-mobile show-on-mob">
			<span
				className="toggler"
				style={{ padding: 3, cursor: 'pointer' }}
				onClick={() => setExpand(!expand)}
			>
				<MenuOutlined style={{ color: '#000' }} />
			</span>
			{expand && (
				<React.Fragment>
					<div
						className="overlay"
						onClick={() => setExpand(false)}
					></div>
					<div className="drop-list">
						{navItems.map((item, index) =>
							!item?.ext ? (
								<p key={index} onClick={() => setExpand(false)}>
									<Link to={item.goto}>
										<span className="link-span">
											{item.text}
										</span>
									</Link>
								</p>
							) : (
								<p key={`ext${index}`}>
									<a
										target="blank"
										alt=""
										href="https://exam.somatmba.com"
									>
										<span className="link-span">
											{item.text}
										</span>
									</a>
								</p>
							)
						)}
					</div>
				</React.Fragment>
			)}
		</div>
	)
}
